import React from "react";
import FooterNext from "../Footers/FooterNext";


function TermsAndConditions() {
  return (
    <div style={{ background: "black" }}>
      <h1 style={{ color: "white" }}><center>ONLINE PLATFORM SERVICE AGREEMENT</center></h1>
      <div style={{ color: "white" }}>THIS ONLINE SERVICE AGREEMENT is executed between Sungowin Technologies Pvt. Ltd. having its office at 54-55, Arun Vihar, Jaipur- 302012, hereinafter to be referred as the Service Interface Platform Provider on the First Part and you hereinafter to be referred as the Client on the Second Part, as and where the context so arises.</div>
      <div>
        <div style={{ color: "white" }}>ACCEPTANCE CLAUSE</div>
        <div style={{ color: "white" }}>You are advised to read the terms and conditions of this Agreement fully before signing and login the Service Interface Platform of the Service Interface Platform Provider. You are informed that by clicking the “I agree and accept” button, you give your consent to abide by all the terms and conditions enumerated in this agreement and you further consent to use the Service Interface Platform accordingly. If you are working in a Company, a Partnership Firm or any other Legal Entity etc. and if you click the “I agree and accept” button, it shall be presumed that you are duly competent and authorized by your Organization to sign this agreement on their behalf. The Service Interface Platform Provider shall not be responsible for any future dispute with regard to your competence and authorization raised by your Organization. You and your Organization shall be duly responsible for all the consequences that may arise out of the use of this Service Interface Platform. </div>
        <div style={{ color: "white" }}>
        DEFINITIONS:
        1.	All the terms mentioned in this Online Platform Service Agreement are, in their cognate variations, expressed in masculine gender. Their respective reference should be taken in the context of the clients as per their specific gender, which may be male, female, third gender or neutral gender for companies, corporations etc.
        2.	Responding Entity shall mean in all its cognate variations, the person, corporation, company, partnership firm or any other legal entity etc., who responds to the requirements of the client in response to his requirements published through the Service Interface Platform.

        3.	Service Interface Platform fees shall include the moneys paid by the client to the Service Interface Platform Provider for using the Service Interface Platform.
        TERMS and CONDITIONS FOR USE OF THIS SERVICE INTERFACE PLATFORM:
        1.	That, the Service Interface Platform can be used by the client after making the payment of the fees to the Service Interface Platform Provider. The applicable government levies and taxes like GST, VAT or any other government levies and taxes, which may be imposed by the appropriate government from time to time, shall be chargeable on the fees which shall be extra and in addition to the amount of fees and shall be borne by the client.
        2.	That, Service Interface Platform Provider is merely providing an Interface Platform to the client wherein he can post his lawful requirements of any type and to seek the response from the prospective Responding Entity on this Platform. This services on this Platform can be availed only to this extent. Once the response from the Responding Entity is received by the client, the legal obligations of the Service Interface Platform Provider shall end with regard to the said particular requirement of the client.
        3.	That, the Service Interface Platform Provider shall not have any privity with respect to any terms and conditions or any agreements etc. or with respect to any transactions, or with respect to any interactions etc. which may be entered into by the client and the Responding Entity once they establish contact through the Service Interface Platform. The responsibilities and obligations of the Service Interface Platform Provider shall cease and come to an end with respect to the client and the Responding Entity immediately after posting the requirements of the client and after receiving the response from then Responding Entity.
        4.	That, the client can post any number of his requirements during the tenure when this agreement is live and operative and he continuous to make payment of the requisite fees and the government levies and taxes etc.

        5.	That, the Service Interface Platform Provider does not give any guarantee to the client with regard to the satisfaction of his requirements. Merely, posting of the requirements by the client shall not be presumed or taken as guarantee to meet and satisfy the requirements of the client. The client understands and agrees that this Service Interface Platform is merely an Interface between him and the Responding Entity. The response on his requirement is completely the prerogative and discretion of the Responding Entity, who may opt to respond or may not respond. The Service Interface Platform Provider shall not be held responsible and liable for not receiving any response by the Responding Entity on the requirements posted on this Service Interface Platform.
        6.	That, all the transactions and interactions which may be executed between the client and the Responding Entity shall be settled by themselves on their own terms and conditions for which Service Interface Platform Provider shall have no privity. The Service Interface Platform Provider shall not be held liable or responsible either in fiduciary capacity or otherwise for any of the consequences that may ensue due to their agreements.
        7.	That, though all reasonable steps shall be taken by the Service Provider to transmit the messages and the requirements of the client on its Service Interface Platform but there may be circumstances beyond the control of the Service Interface Platform Provider, which may obstruct or hinder or may block, either temporarily or permanently the transmission of the requirements of the client on the Service Interface Platform, which are unforeseen. The client shall not cast any responsibility or liability upon the Service Interface Platform Provider for non-transmission of his requirements due to such unforeseen and unavoidable circumstances beyond the control of the Service Interface Platform Provider. The Service Interface Platform Provider shall owe no responsibility towards the client or any third party in such circumstances. In such circumstances the client shall not hold responsible the Service Interface Platform Provider and shall not cast any liability or responsibility either on monetary terms or otherwise upon the Service Interface Platform Provider, for any loss occurred by the client in any respect including monetary losses or losses occurring due to his non-providing the services to his client etc. No action for damages or for specific performance or the contract or on any ground can be initiated against the Service Interface Platform Provider by the client to which the client agrees and consents unconditionally.
        8.	That, the client understands and agrees that the services on the Service Interface Platform provided by the Service Interface Platform Provider are subject to the business policies of the WhatsApp or any other such Platform. WhatsApp or any other such Platform can block, obstruct, hinder, stop permanently or can even discontinue the facilities and services to the Service Interface Platform Provider either severally confined to it or generally for all the users. In such circumstances the client shall not hold responsible the Service Interface Platform Provider and shall not cast any liability or responsibility either on monetary terms or otherwise upon the Service Interface Platform Provider, for any loss occurred by the client in any respect including monetary losses or losses occurring due to his non-providing the services to his client etc. No action for damages or for specific performance or the contract or on any ground can be initiated against the Service Interface Platform Provider by the client to which the client agrees and consents unconditionally.
        9.	That, the client understands and agrees that the services made available by the Service Interface Platform Provider on Service Interface Platform are dependent upon the availability of Net services which in return are provided by the Telecom Service Provider. If the signals of the Network Services are weak or not available, the transmission of the requirements of the client on the Service Interface Platform can be obstructed, delayed, hindered or they may not even be transmitted permanently. This condition is unforeseen and beyond the control of the Service Interface Platform Provider. Thus, in such circumstances, the client shall not hold the Service Interface Platform Provider responsible and liable for non-transmission of his requirements or for any loss of money or services that may consequently be suffered by the client due to this reason. In such circumstances the client shall not hold responsible the Service Interface Platform Provider and shall not cast any liability or responsibility either on monetary terms or otherwise upon the Service Interface Platform Provider, for any loss occurred by the client in any respect including monetary losses or losses occurring due to his non-providing the services to his client etc. No action for damages or for specific performance or the contract or on any ground can be initiated against the Service Interface Platform Provider by the client to which the client agrees and consents unconditionally.
        10.	That, the Service Interface Platform Provider does not guarantee the protection of the IPR of the client or the Responding Entity. The Service Interface Platform Provider is only providing an Interface Platform for transmission of the needs and requirements of the client and response of the Responding Entity on its Interface Platform. Any breach of the IPR of the client or the Responding Entity shall be a matter for which the Service Interface Platform Provider shall not be held liable or responsible and no legal action in any form either under the Civil Law or Criminal Laws or under the provisions of the Information Technology Act, 2000 or any other existing or prospective law can be brought against the Service Interface Platform Provider by the client or the Responding Entity in any Court, Tribunal or any other Quasi-Judicial Authority. The client fully agrees and consents to use this Service Interface Platform on this condition.
        11.	That, under its Privacy Policy, the Service Interface Platform Provider shall take all reasonable measures and steps to protect the name and data of the client along with the information of the requirements transmitted through its Service Interface Platform. However, due to hacking, phishing or any other cyber-crime by any cyber-criminal, such informations may be stollen by such cyber-criminals for which the Service Interface Platform Provider shall not be held liable and responsible in any manner whatsoever by the client or the Responding Entity and no legal action in this regard by the client shall be maintainable against the Service Interface Platform Provider in any Court of Law, Tribunal or any other Quasi-Judicial Authority.
        12.	That, the Service Interface Platform Provider shall endeavor to stick to maintain confidentiality of the client in all respects.
        13.	That, this Online Platform Service Agreement is subject to and is governed by the Laws in force and effective in India.
        14  That, all charges are inclusive of all taxes and are non-refundable.
        15.	That, this agreement is subject to the jurisdiction of the Courts situated at Jaipur only. If any dispute with regard to the condition of this agreement is raised, the parties shall endeavor to settle it amicably amongst themselves mutually and litigation shall be the last resort.
        </div>
      </div>
      <div><FooterNext /></div>
    </div>
  );
}

export default TermsAndConditions;
