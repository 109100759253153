import React, { useState, useContext, useEffect } from "react";
import { storage, db } from "../firebaseConfig";
import firebase from "firebase";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import { Button } from "react-bootstrap";
import ShowBranchImages from "../components/showBranchImages";
import ShowPlanImages from "../components/showPlanImages";

export default function ShowPlans({
}) {
  const [user, setUser] = useState("");
  const [functionName, setFunctionName] = useState("");
 
  const [nextList, setNextList] = useState();
  useEffect(() => {
    
    const showImageRef = firebase.database().ref("safeplan/type");
    showImageRef.on("value", (snapshot) => {
      const n = snapshot.val();
      const nextList = [];
      for (let id in n) {
        nextList.push({ id, ...n[id] });
      }
      setNextList(nextList);
    });

  }, []);

  
  return (
    
    <div className="columns-2">
        {nextList
        ? nextList.map((next, index) => <ShowPlanImages style={{marginTop:"15px"}} next={next} key={index} />)
        : ''}
    </div>
  );
}
