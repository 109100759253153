import React, { useEffect, useState, useRef } from 'react';

function TrustedBy() {
  const [doctors, setDoctors] = useState(0);
  const [hospitals, setHospitals] = useState(0);
  const sectionRef = useRef(null); // Reference to the section

  useEffect(() => {
    const doctorsCount = 3000;
    const hospitalsCount = 500;

    // Function to handle intersection (when section comes into view)
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Start counting when the section comes into view

          // Doctors counting interval (faster)
          let doctorsInterval = setInterval(() => {
            setDoctors((prev) => {
              if (prev >= doctorsCount) {
                clearInterval(doctorsInterval); // Stop when we reach the target
                return doctorsCount;
              }
              return prev + Math.ceil(doctorsCount / 100);
            });
          }, 30); // Faster increment

          // Hospitals counting interval (slower)
          let hospitalsInterval = setInterval(() => {
            setHospitals((prev) => {
              if (prev >= hospitalsCount) {
                clearInterval(hospitalsInterval); // Stop when we reach the target
                return hospitalsCount;
              }
              return prev + Math.ceil(hospitalsCount / 120); // Slower increment
            });
          }, 40); // Slower increment

          // Stop observing once the animation is triggered
          observer.unobserve(entry.target);
        }
      });
    };

    // Set up Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% of the section is in view
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="trusted-by-container">
      <style>
        {`
          .trusted-by-container {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .trusted-by-heading {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .trusted-by-heading .loved {
            color: white;
          }

          .trusted-by-heading .trusted-by {
            color: #ff007f; /* Pink */
          }

          .trusted-by-numbers {
            display: flex;
            justify-content: center;
            gap: 3rem;
            margin-bottom: 2rem;
            font-family: 'Montserrat', sans-serif;

          }

          .trusted-by-number {
            font-size: 2rem;
            font-weight: bold;
            animation: fadeIn 1.5s ease-in-out;
            font-family: 'Montserrat', sans-serif;

          }

          /* Animations */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Mobile Responsiveness */
          @media (max-width: 768px) {
            .trusted-by-numbers {
              flex-direction: column;
              gap: 1rem;
            }

            .trusted-by-number {
              font-size: 1.5rem;
            }
          }
        `}
      </style>
      <h2 className="trusted-by-heading">
        <span className="loved">Loved and </span><span className="trusted-by">Trusted By</span>
      </h2>
      <div className="trusted-by-numbers">
        <p className="trusted-by-number">{doctors}+ Doctors</p>
        <p className="trusted-by-number">{hospitals}+ Hospitals</p>
      </div>
    </div>
  );
}

export default TrustedBy;
