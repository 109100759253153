import React, { useState } from "react";

import firebase from "firebase";
import { db } from "../firebaseConfig";

import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Carousel from "react-bootstrap/Carousel";
import ShowImages_school_corporate from "./showimages_school_corporate";
import PostRequest from "./components/postRequest.js";
import ShortcutPostRequest from "./components/ShortcutPostRequest";
import ShowProducts from "../components/showProducts";
import FooterNew from "../Footers/Footer";
import ShowPlans from "./showPlans";
import FooterNext from "../Footers/FooterNext";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';


export default function Safeplan() {



  const [posts, setPosts] = useState([]);
  db.collection("slideshowschool").onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });

  return (
    <div style={{background:"black", marginTop:"-4%"}}>

      {/*<div class="container body-content" style={{ marginTop: "35px" }}>
        <ShowProducts path="slideshowschool" />
  </div>*/}

      {/*<div class="container body-content" style={{ marginTop: "35px" }}>
        <ShortcutPostRequest />
</div>*/}

      <div class="container body-content" style={{ backgroundColor:"black", marginTop: "45px" }}><ShowPlans /></div>
      <div style={{ marginTop: "45px" }}><FooterNext /></div>
    </div>
  );
}

