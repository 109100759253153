import React from "react";
import { Link } from "react-router-dom";
import "./FooterNext.css";
import { FooterLink } from "./FooterStyles";

export default function FooterNext({ }) {



    return (
        <div className="footer">
            
            <div className="sb__footer section__padding">
            <hr style={{color:"white"}}></hr>
                <div className="sb__footer-links">
                    <div className="sb__footer-links-div">
                        <h4>Schools/Corporate</h4>
                        <a href="safeplan">
                            <p>
                                Request Visiting Doctor
                            </p>
                        </a>
                        <a href="safeplan">
                            <p>
                                Employee Health Checkup Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p>
                                Student Health Checkup Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p>
                                Vaccination Plans
                            </p>
                        </a>
                        <a href="safeplan">
                            <p>
                                Educational Sessions
                            </p>
                        </a>
                    </div>

                    <div className="sb__footer-links-div">
                        <h4>Medical Students</h4>
                        <a href="campus-ambassador-program">
                            <p>
                                Campus Ambassador Program
                            </p>
                        </a>
                        <a href="creator-connect">
                            <p>
                                Creator Connect
                            </p>
                        </a>
                        <a href="affiliate-connect">
                            <p>
                                Affiliate Connect
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>Doctors</h4>
                        <a href="doctor">
                            <p>
                                Doctor Membership
                            </p>
                        </a>
                        <a href="educator-registration">
                            <p>
                                Educators
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4>Hospitals</h4>
                        <a href="hospital-membership">
                            <p>
                                Hospital Membership
                            </p>
                        </a>
                        {/*<a href="#">
                            <p>
                            Diagnostic Centre Registration
                            </p>
    </a>*/}
                        <a href="list-training-course">
                            <p>
                                List Training Course
                            </p>
                        </a>
                        <a href="list-observership-plan">
                            <p>
                                List Observership Plan
                            </p>
                        </a>
                    </div>
                    <div className="sb__footer-links-div">
                        <h4 style={{color:" #ff007f"}}>Follow us on Social Media</h4>
                        <div className="socialmedia">
                            <FooterLink target="_blank" href="https://www.facebook.com/DrPlanete">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-facebook-f" />
                            </FooterLink>
                            <FooterLink target="_blank" href="https://www.instagram.com/drplanete/">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-instagram" />
                            </FooterLink>
                            <FooterLink target="_blank" href="https://www.youtube.com/@drplanete4495">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-twitter" />
                            </FooterLink>

                            <FooterLink target="_blank" href="https://www.linkedin.com/company/dr-planete/">
                                <i style={{ color: "white", marginRight: "15px" }} className="fab fa-linkedin" />
                            </FooterLink>
                        </div>

                        <div>

                        <a href="https://apps.apple.com/in/app/dr-planete/id6473446013">
        <img 
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83" 
        alt="Download on the App Store" 
        style={{ width: "150px", height: "80px", marginLeft:"9px"}}
        />
        </a>

                            <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img
                                style={{width: "170px", height: "80px", marginTop:"-9px"}}
            
                                    class="google_play"
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"

                                />
                            </a>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>

                <hr style={{color:"white"}}></hr>

                <div>
                    <h4 style={{color:"white"}}>Popular <span style={{color:"#ff007f"}}>Searches</span></h4>

                    <p style={{color:"white"}}>Doctor jobs Delhi | Doctor jobs Mumbai | Doctor jobs Hyderabad | Doctor jobs Chennai | Doctor jobs Jaipur | Doctor jobs Indore | Doctor jobs Bhopal | Doctor jobs Pune </p>
                </div>
                <hr style={{color:"white"}}></hr>

                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">

                        <p>
                            <i className="far fa-copyright">{"  "}</i>
                            2023 Sungowin Technologies Pvt. Ltd. All rights reserved.
                        </p>

                        <p>CIN: U72900RJ2022PTC079589</p>
                        <p>GSTIN: 08ABHCS8438B1Z7 </p>
                        <p>Registered Address: 54-55 Arun Vihar Niwaroo Road Jaipur - 302012</p>
                        <p>Phone: +91 8619460759</p>
                    </div>
                    <div className="sb__footer-below-links">

                    <a href="contact">
                            <div>
                                <p>Contact</p>
                            </div>
                        </a>

                        <a href="careers">
                            <div>
                                <p>Careers</p>
                            </div>
                        </a>

                        <a href="termsandconditions">
                            <div>
                                <p>Terms & Conditions</p>
                            </div>
                        </a>
                        <a href="privacypolicy">
                            <div>
                                <p>Privacy Policy</p>
                            </div>
                        </a>
                        <a href="#">
                            <div>
                                <p>FAQ</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}