import React from 'react';
import PremierClubPage from './premierClub';
import LiveBenefits from './sectionOne';
import LearnBenefits from './sectionTwo';
import GrowBenefits from './sectionThree';
import BecomeAMember from './membershipInstructions';
import TrustedBy from './sectionFour';
import FooterNext from '../Footers/FooterNext';
import Testimonials from './testimonials';

function NewHomePage() {
  return (
    <div>
      <PremierClubPage/>
      
         <LiveBenefits/>
      <LearnBenefits/>
      <GrowBenefits/>
      <Testimonials/>
      <TrustedBy/>
      <BecomeAMember/>
      <FooterNext/>
      </div>
     
   
    
  );
}

export default NewHomePage;

