import React, { useState } from "react";
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';

import { Button } from "react-bootstrap";
import Login from "../../Login"
import FooterNew from "../../Footers/Footer";
import ClientDetails from "../components/clientDetails";
import SessionDetails from "../components/sessionDetails";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TnC from "../components/tnc";

import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FooterNext from "../../Footers/FooterNext";


export default function EducationSession() {
  // Inputs

  const [user] = useAuthState(cheq);

  const [todayDate, setTodayDate] = useState(new Date());
  const [reqDate, setReqDate] = useState(new Date());


  const [cityName, setCityName] = useState("");
  const [description, setDescription] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  
  const [orgName, setOrgName] = useState("");
  const [orgRepName, setOrgRepName] = useState("");
  const [orgRepDesignation, setRepDesignation] = useState("");
  const [orgRepOfficialEmail, setOrgRepOfficialEmail] = useState("");
  const [orgCompleteAddress, setOrgCompleteAddress] = useState("");

  const cityOptions = [
    { label: 'Bengaluru' },
    { label: 'Delhi' },
    { label: 'Hyderabad' },
    { label: 'Mumbai' },
    { label: 'Pune' },
  ];

  const specOptions = [
    { label: 'Bengaluru' },
    { label: 'Delhi' },
    { label: 'Hyderabad' },
    { label: 'Mumbai' },
    { label: 'Pune' },
  ];



  const budgetOptions = [
    { label: 'upto INR 5000' },
    { label: 'INR 5000 - INR 10000' },
    { label: 'INR 10000 - INR 15000' },
    { label: 'INR 15000 - INR 20000' },
    { label: 'INR 20000 - INR 40000' },
    { label: 'above INR 40000' },
  ];

  function postRequest() {

    if (cityName === "") {
      alert("Please enter City");
      return;
    }

    if (description === "") {
      alert("Please enter Description");
      return;
    }

    if(specialization === ""){
      alert("Please select Specialization")
      return;
    }

    if (totalBudget === "") {
      alert("Please enter Total budget");
      return;
    }

    if (orgName === "" || orgRepName === "" || orgRepDesignation === "" || orgRepOfficialEmail === "" ||
      orgCompleteAddress === "") {
      alert("Please enter Organization Details");
      return;
    }

    db.collection("session-safeplan").add({

      PosterPhone: cheq.currentUser.phoneNumber,
      city: cityName,
      description: description,
      specialization: specialization,
      totalBudget: totalBudget,

      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate: todayDate,
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),

    });

    database.ref("onlinerequest/safeplan/session").push({

      PosterPhone: cheq.currentUser.phoneNumber,
      city: cityName,
      description: description,
      specialization: specialization,
      totalBudget: totalBudget,


      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate: todayDate,
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),

    }).catch(alert);

    window.location.replace('https://rzp.io/l/drplanete-education-session');
    
    /*alert("city name - " + cityName + "\n" + 
    description + "\n" + specialization + "\n" + totalBudget + "\n" + orgName + "\n" + orgRepName + "\n" + orgRepDesignation + "\n" + orgRepOfficialEmail + "\n" + 
    orgCompleteAddress + "\n" + dayjs(todayDate).format('L') + "\n" + dayjs(reqDate).format('LL'));
    */
    /*alert("city Name - " + cityName + "\n" + headCount + "\n" + budgetPP + "\n" +
    orgName + "\n" + orgRepName + "\n" + orgRepDesignation + "\n" + orgRepOfficialEmail + "\n" + 
    orgCompleteAddress + "\n" + dayjs(todayDate).format('L') + "\n" + dayjs(reqDate).format('LL'));

    */
  }
  return (
    user ? <>
      <div style={{ background: "white" }}>
        <div><img
          className="w-full h-[440px] object-cover"
          style={{ width: "1600px", height: "300px" }}
          src="https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/safeplan%2Fweb-heads%2Feducation%20session.png?alt=media&token=308bb6a2-c41a-4133-865c-5ba6cf1b6cf5"
          alt=""
        /></div>
  
        <div style={{ marginTop: "45px" }}>
          <center>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={cityOptions}
              sx={{ width: 400 }}
              onChange={(event, value) => setCityName(value.label)}
              renderInput={(params) => <TextField {...params} label="Select City" />}
            />
          </center>
        </div>
  
        <div style={{ background: "white" }}>
          <div className="container body-content border-1 border-indigo-600" style={{ marginTop: "35px" }} >
            <div style={{ background: "white" }}>
              <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                <TextField id="outlined-basic"
                  multiline fullWidth
                  maxRows={4}
                  label="Please describe session" variant="outlined" onChange={(e) => setDescription(e.target.value)} />
              </div>
  
              <div style={{ marginBottom: "15px" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={specOptions}
                  sx={{ width: 700 }}
                  onChange={(event, value) => setSpecialization(value.label)}
                  renderInput={(params) => <TextField {...params} label="Specialization" />}
                />
              </div>
  
              <div style={{ marginBottom: "15px" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={budgetOptions}
                  sx={{ width: 700 }}
                  onChange={(event, value) => setTotalBudget(value.label)}
                  renderInput={(params) => <TextField {...params} label="Total Budget" />}
                />
              </div>
  
              <div style={{ marginTop: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={reqDate}
                    onChange={(newValue) => {
                      setReqDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <p>You selected {dayjs(reqDate).format('LL')}</p>
            </div>
          </div>
        </div>
  
        <div style={{ background: "white" }}>
          <div className="container body-content border-1 border-indigo-600" style={{ marginTop: "15px", marginBottom: "35px", background: "white"  }}>
            <div style={{ marginTop: "35px", marginBottom: "15px", width: "450px",padding: "15px", }}>
              <TextField id="outlined-basic" fullWidth label="Organization Name" variant="outlined" onChange={(e) => setOrgName(e.target.value)} />
            </div>
            <div style={{ marginTop: "35px", marginBottom: "15px" }}>
              <TextField id="outlined-basic" fullWidth label="Organization Representative Name" variant="outlined" onChange={(e) => setOrgRepName(e.target.value)} />
            </div>
            <div style={{ marginTop: "35px", marginBottom: "15px" }}>
              <TextField id="outlined-basic" fullWidth label="Organization Representative Designation" variant="outlined" onChange={(e) => setRepDesignation(e.target.value)} />
            </div>
  
            <div style={{ marginTop: "35px", marginBottom: "15px" }}>
              <TextField id="outlined-basic" fullWidth label="Representative Official Email ID" variant="outlined" onChange={(e) => setOrgRepOfficialEmail(e.target.value)} />
            </div>
            <div style={{ marginTop: "35px", marginBottom: "15px" }}>
              <TextField id="outlined-basic"
                multiline fullWidth
                maxRows={4}
                label="Organization Address" variant="outlined" onChange={(e) => setOrgCompleteAddress(e.target.value)} />
            </div>
  
          </div>
        </div>
  
        <div style={{ background: "white" }}>
          <div className="container body-content border-1 border-indigo-600">
            <div>
              <div><center><h3>Note</h3></center></div>
              <div><p className="text-sm">1. Dr Planete is merely providing an Interface Platform to the client wherein the client can post/request to post his lawful requirements as eligible on the platform and seek the response from the prospective Responding Entity on this platform.</p></div>
              <div><p className="text-sm">2. Dr Planete shall not have any privity with respect to any terms and conditions or any agreements etc. or with respect to any transactions, or with respect to any interactions, etc. which may be entered into by the client and the Responding Entity once they establish contact through the platform.</p></div>
              <div><p className="text-sm">3. Dr Planete does not give any guarantee to the client with regard to the satisfaction of his requirements. Merely, posting of the requirements by the client shall not be presumed or taken as a guarantee to meet and satisfy the requirements of the client. The client understands and agrees that this Service Interface Platform is merely an interface between the client and the Responding Entity.</p></div>
              <div className="text-sm"><p>4. All charges are inclusive of all taxes applicable and are non-refundable.</p></div>
              <div><p className="text-sm">5. Dr Planete shall not be responsible for any future dispute with regard to your competence and authorization by your Organization.</p></div>
              <div><p className="text-sm">6. You and your Organization shall be duly responsible for all the consequences that may arise out of the use of this Service Interface Platform. It’s the responsibility of the client to keep updated and comply with the terms and conditions of the interface platform.</p></div>
  
            </div>
  
            <div>
              <center>
                <p>By continuing, I agree to all Terms & Conditions</p>
              </center>
            </div>
  
            <div style={{ marginTop: "25px", marginBottom: "25px" }}>
              <center>
                <Button variant="primary" size="lg" onClick={postRequest}>Post Request</Button>
              </center>
            </div>
          </div>
        </div>
  
        <div><FooterNext /></div>
      </div>
    </> : <Login />
  );
  
}
