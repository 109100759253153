import React, { useEffect, useState } from "react";

const Testimonials = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 } // Trigger animation when 20% of the section is visible
    );

    const section = document.querySelector(".testimonials-section");
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <>
      <style>
        {`
          .testimonials-section {
            padding: 2rem;
            background-color: #000;
            color: #fff;
            text-align: center;
          }

          .section-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .section-title span {
            color: #ff0077;
          }

          .testimonials-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 1.5rem;
            opacity: 0;
            transform: translateY(50px);
            transition: all 1s ease-in-out;
          }

          .testimonials-container.animate {
            opacity: 1;
            transform: translateY(0);
          }

          .testimonial {
            background: #1a1a1a;
            padding: 1.5rem;
            border-radius: 0.75rem;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
            position: relative;
          }

          .testimonial:before {
            content: "“";
            font-size: 3rem;
            color: #ff0077;
            position: absolute;
            top: -15px;
            left: 15px;
          }

          .testimonial:after {
            content: "”";
            font-size: 3rem;
            color: #ff0077;
            position: absolute;
            bottom: -15px;
            right: 15px;
          }

          .testimonial h4 {
            margin-top: 1rem;
            color: #ff0077;
            font-size: 1rem;
          }
        `}
      </style>
      <div className="testimonials-section">
        <h2 className="section-title">
          Our Members <span>Say</span>
        </h2>
        <div className={`testimonials-container ${isVisible ? "animate" : ""}`}>
          <div className="testimonial">
            <p>
              "I am able to do both - prepare for NEET-PG and work. With Dr
              Planete, I can find on-call locums, part-time duties according to
              my schedule."
            </p>
            <h4>Dr Shreya, MBBS</h4>
          </div>
          <div className="testimonial">
            <p>
              "It's easier to find anesthetists with Dr Planete. Now I can focus
              on the patient instead of calling every anesthetist one by one."
            </p>
            <h4>Dr Shilpa, Gynecologist</h4>
          </div>
          <div className="testimonial">
            <p>
              "I was spending a lot on marketing. After connecting with Dr
              Planete's personal marketing advisor, I saved over 50% on
              marketing costs through targeted reach and streamlined efforts."
            </p>
            <h4>Dr Mahendra Gupta, Pediatrician</h4>
          </div>
          <div className="testimonial">
            <p>
              "My hospital is on the outskirts of Bikaner, finding specialist
              and super-specialist doctors has always been a challenge. Dr
              Planete helped us connect with nearby doctors and invite
              specialists to visit regularly, improving our services and
              revenue."
            </p>
            <h4>Dr Ashish</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
