import React from "react";

import doctorImage from "./images/doctors.png";
import hospitalImage from "./images/hospitals.png";
import medicalStudentImage from "./images/medicalstudents.png";

function PremierClubPage() {
  return (
    <div className="premier-club-page">
      <style>
        {`
          /* Main Page Styles */
          .premier-club-page {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: "Poppins", sans-serif;
            font-weight: bold;
            text-align: center;
          }

          .premier-club-page h1 {
            font-size: 9vw; /* Responsive heading size */
            margin: 0;
            line-height: 1.2;
            word-wrap: break-word;
            animation: breathe 3s infinite alternate; /* Breathe animation for heading */
          }

          .premier-club-page h1 .pink-text {
            color: #ff007f;
          }

          .premier-club-subtext {
            font-size: 4rem;
            font-style: italic;
            margin-bottom: 3rem;
            font-family: "Windsong", cursive;
            color: white;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            animation: typing 4s steps(12, end) 1s, hide-cursor 0.1s forwards 4s; /* Typing animation + hiding cursor after typing */
            position: relative; /* Ensures it doesn't get overlapped */
            z-index: 10; /* Bring the text above other elements */
          }

          .premier-club-subtext span {
            display: inline-block;
            border-right: 2px solid white; /* Cursor effect */
            animation: typing 4s steps(12, end) 1s, hide-cursor 0.1s forwards 4s;
          }

          .premier-club-cards {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
            opacity: 0; /* Cards are hidden initially */
            animation: fadeIn 0.5s forwards 5s; /* Start after typing completes */
            justify-content: center;
            position: relative; /* Ensures cards don’t overlap the text */
          }

          .premier-club-card {
            width: 30%;
            padding: 0rem;
            background-color: #ffffff;
            border-radius: 1rem;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            margin-left: 2%;
            opacity: 0; /* Start hidden */
            transform: scale(0); /* Start scaled down */
            animation: popIn 0.5s ease-in-out forwards;
            position: relative; /* Cards have a relative position */
          }

          /* Individual Card Animation Delay */
          .card-doctors {
            background-color: black;
            animation-delay: 5.5s;
          }

          .card-hospitals {
            background-color: transparent;
            animation-delay: 6.5s;
          }

          .card-students {
            background-color: black;
            animation-delay: 7.5s;
          }

          .premier-club-card img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
            display: block;
            max-width: 100%;
          }

          /* Animations */
          @keyframes breathe {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(1.1);
            }
          }

          @keyframes typing {
            from {
              width: 0;
            }
            to {
              width: 100%;
            }
          }

          /* Cursor disappears immediately after typing is done */
          @keyframes hide-cursor {
            to {
              border-color: transparent; /* Make the cursor disappear */
            }
          }

          /* Cards fade in after typing */
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes popIn {
            from {
              opacity: 0;
              transform: scale(0);
            }
            to {
              opacity: 1;
              transform: scale(1);
            }
          }

          /* Responsive Design for Mobile */
          @media (max-width: 768px) {
            .premier-club-page h1 {
              font-size: 12vw; /* Smaller heading on mobile */
            }

            .premier-club-subtext {
              font-size: 2rem; /* Smaller subtext font size */
              margin-bottom: 1.5rem; /* Reduced margin */
            }

            .premier-club-cards {
              flex-direction: column; /* Stack cards vertically */
              gap: 2rem; /* Add more space between cards */
              margin-top: 0;
            }

            .premier-club-card {
              width: 80%; /* Increase card width for mobile */
              margin-left: 0; /* Remove left margin */
            }
          }

          @media (max-width: 480px) {
            .premier-club-page h1 {
              font-size: 14vw; /* Even smaller heading for small screens */
            }

            .premier-club-subtext {
              font-size: 1.5rem; /* Even smaller subtext font size */
              margin-bottom: 1rem; /* Reduced margin */
            }

            .premier-club-cards {
              gap: 1.5rem; /* Reduced gap between cards */
            }

            .premier-club-card {
              width: 90%; /* Further increase card width */
            }
          }
        `}
      </style>

      <div className="premier-club-page" style={{ marginTop: "5%" }}>
        <h1>
          <span> The </span>
          <span> Premier </span>
          <span className="pink-text">Club</span>
        </h1>
      </div>

      {/* Centered "Only for" text with typing animation */}
      <div className="premier-club-subtext">
        <span>Only for</span>
      </div>

      {/* Cards section, will fade in after typing animation */}
      <div className="premier-club-cards" style={{ marginTop: "-10%" }}>
        <div className="premier-club-card card-doctors">
          <img src={doctorImage} alt="Doctors" />
        </div>

        <div className="premier-club-card card-hospitals">
          <img src={hospitalImage} style={{ marginTop: "0%" }} alt="Hospitals" />
        </div>

        <div className="premier-club-card card-students">
          <img src={medicalStudentImage} alt="Medical Students" />
        </div>
      </div>
    </div>
  );
}

export default PremierClubPage;
