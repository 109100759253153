import React from "react";
import FooterNext from "../../Footers/FooterNext";


const DoctorJobsDelhi = () => {
  return (

    <div>
    {/*<main style={styles.doctorJobsContainer}>
      <section style={styles.introSection}>
        <h1 style={styles.heading}>Doctor Jobs in Delhi</h1>
        <p style={styles.paragraph}>
          Looking for the perfect <strong>doctor job in Delhi</strong>? Delhi offers
          unparalleled opportunities for <strong>medical professionals</strong>, from government institutions to
          leading private hospitals. Keep reading to explore the roles,
          opportunities, and how <strong>Dr Planete</strong> can help you achieve your dream job.
        </p>
      </section>

      <section style={styles.designationsSection}>
        <h2 style={styles.subheading}>Explore Popular Medical Designations in Delhi</h2>
        <p style={styles.paragraph}>
          Doctors in Delhi are employed across diverse designations, each with
          unique responsibilities and opportunities for growth. Here are the top <strong>medical roles</strong> in Delhi:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Junior Resident</li>
          <li style={styles.listItem}>Senior Resident</li>
          <li style={styles.listItem}>Consultant</li>
          <li style={styles.listItem}>Associate Consultant</li>
          <li style={styles.listItem}>Head of Department (HOD)</li>
          <li style={styles.listItem}>Visiting Consultant</li>
        </ul>
        <p style={styles.paragraph}>
          These positions provide doctors with opportunities to grow and contribute to the healthcare sector in Delhi.
        </p>
      </section>

      <section style={styles.governmentHospitalsSection}>
        <h2 style={styles.subheading}>Top Government Hospitals in Delhi</h2>
        <p style={styles.paragraph}>
          Delhi is home to some of India’s most prestigious government hospitals.
          These institutions regularly have active openings for talented
          doctors. Here’s a list of renowned <strong>government hospitals in Delhi</strong>:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>All India Institute of Medical Sciences (AIIMS), Delhi</li>
          <li style={styles.listItem}>Safdarjung Hospital</li>
          <li style={styles.listItem}>Ram Manohar Lohia Hospital</li>
          <li style={styles.listItem}>Lady Hardinge Medical College</li>
          <li style={styles.listItem}>Guru Teg Bahadur Hospital</li>
          <li style={styles.listItem}>Deen Dayal Upadhyay Hospital</li>
          <li style={styles.listItem}>Lok Nayak Hospital</li>
        </ul>
        <p style={styles.paragraph}>
          The list of hospitals with active openings is continuously updated. To
          ensure you don’t miss any opportunity, keep visiting this page
          regularly and stay ahead in your job search.
        </p>
      </section>

      <section style={styles.drPlaneteSection}>
        <h2 style={styles.subheading}>Find Your Dream Job with Dr Planete</h2>
        <p style={styles.paragraph}>
          At <strong>Dr Planete</strong>, we understand the importance of finding the right fit
          for your career. Here’s how our platform empowers doctors looking for <strong>doctor jobs in Delhi</strong>:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <strong>Job Listings:</strong> Discover exclusive opportunities at
            leading hospitals in Delhi tailored to your expertise.
          </li>
          <li style={styles.listItem}>
            <strong>On-Call Opportunities:</strong> Dr Planete’s open platform
            allows you to view and accept on-call shifts at hospitals near you
            with ease.
          </li>
          <li style={styles.listItem}>
            <strong>Proximity Search:</strong> Use our advanced search tools to
            find openings close to your location.
          </li>
          <li style={styles.listItem}>
            <strong>Dream Match:</strong> We help you align your aspirations
            with the right hospitals and roles.
          </li>
        </ul>
        <p style={styles.paragraph}>
          Thousands of doctors have already found their dream roles with Dr
          Planete. Don’t miss out—keep following this page and explore the
          possibilities today!
        </p>
      </section>

      <section style={styles.ctaSection}>
        <h3 style={styles.ctaHeading}>Stay Updated with Dr Planete</h3>
        <p style={styles.paragraph}>
          Opportunities in Delhi’s medical field are constantly evolving. Stay
          ahead by regularly visiting this page and subscribing to our updates.
          Let <strong>Dr Planete</strong> be your trusted companion in your professional journey.
        </p>
      </section>
      
      
    </main>*/}
    
    <FooterNext/>
    </div>
  );
};

const styles = {
  doctorJobsContainer: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    lineHeight: "1.6",
    marginTop:"5%"
  },
  introSection: {
    marginBottom: "20px",
  },
  heading: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#333",
  },
  subheading: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#444",
  },
  paragraph: {
    fontSize: "16px",
    color: "#555",
    marginBottom: "10px",
  },
  list: {
    paddingLeft: "20px",
    marginBottom: "10px",
  },
  listItem: {
    marginBottom: "5px",
    color: "#666",
  },
  drPlaneteSection: {
    backgroundColor: "#f9f9f9",
    padding: "15px",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  ctaSection: {
    textAlign: "center",
    marginTop: "20px",
  },
  ctaHeading: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#222",
  },
  ctaButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default DoctorJobsDelhi;

