import React, { Component } from "react";
import "./OnTop.css";
import {
  Navbar,
  NavDropdown,
  Dropdown,
  Form,
  FormControl,
  Button,
  Nav,
} from "react-bootstrap";

import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

import { useStateValue } from "../StateProvider";
import { cheq } from "../firebaseConfig";
import { MenuItems } from "../MenuItems";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Doctor from "../doctor/Doctor.js";
import Home from "../Home";
import Login from "../Login";
import Hospital from "../hospital/Hospital.js";
import DoctorRegistration from "../doctor/doctorreg";
import MedicalStudent from "../medicalstudent/MedicalStudent";

import DashboardDoctor from "../doctor/dashboardDoctor";
import RequestVisitingDoctor from "../school_corporate/safeplan.js";
import DoctorRegistrationPortal from "../doctor/doctorRegistrationPortal";
import Termsandcondtions from "../termsandconditions/termsandconditions";
import PrivacyPolicy from "../termsandconditions/privacypolicy";

import background from "../img/ontop.png";
class OnTop extends Component {
  state = {
    clicked: false,
  };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <div className="top_strip" style={{background:"black"}}>
  <div className="strip_hello-mobile" style={{background:"black"}}>
    <center>
    <img 
      style={{
        width: "auto",  /* Full width of the screen */
        height: "auto",  /* Maintain aspect ratio */
        maxHeight: "11vh",  /* Maximum height set to 40% of the viewport height */
        objectFit: "fill" /* Ensures the image is fully contained and scaled */
      }} 
      src="/ganeshji dr planete website.png" 
      alt="Ganesh Ji Image"
    />
    </center>
  </div>
</div>

    );
  }
}

export default OnTop;
