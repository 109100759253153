import React from "react";

import personalAdvisor from "./images/personalmarketingadvisor.png";
import onCallsImage from "./images/on_calls.png";
import jobImage from "./images/findjobs.png";

function GrowBenefits() {
  return (
    <div className="growBenefitsContainer">
      <style>
        {`
          .growBenefitsContainer {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .growBenefitsTitle {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .growBenefitsTitle span {
            color: #ff007f; /* Pink */
          }

          .growBenefitsGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            max-width: 1200px; /* Increased width for the grid */
            margin: 0 auto;
          }

          .growBenefitsCard {
            background-color: #333;
            border-radius: 30px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            animation: fadeIn 1.5s ease-in-out;
            height: 200px; /* Maintain consistent height */
            width: 400px; /* Increased horizontal length */
            overflow: hidden; /* Prevent content overflow */
          }

          .growBenefitsCard img {
            width: 60px; /* Adjusted size for better proportions */
            height: auto;
            background-color: #333;
          }

          .growBenefitsCardText {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1rem;
            font-family: "antic";
            color: white;
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflowed text */
            text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
            width: 100%;
            text-align: center;
            background-color: #333;
          }

          .growBenefitsDivider {
            margin: 2rem auto;
            width: 80%;
            height: 2px;
            background-color: #ff007f; /* Pink */
          }

          /* Animations */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Mobile Responsiveness */
          @media (max-width: 768px) {
            .growBenefitsTitle {
              font-size: 2rem;
            }

            .growBenefitsGrid {
              grid-template-columns: 1fr; /* Stack cards vertically on small screens */
              max-width: 90%; /* Ensure cards occupy 90% of the screen width */
            }

            .growBenefitsCard {
              width: 100%; /* Make sure the card takes up the full width */
              height: 160px; /* Adjust height for smaller screens */
            }

            .growBenefitsCard img {
              width: 50px; /* Slightly smaller images for mobile */
            }

            .growBenefitsCardText {
              font-size: 1.3rem; /* Adjust text size for readability */
            }
          }

          @media (max-width: 480px) {
            .growBenefitsTitle {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            .growBenefitsCard {
              padding: 1rem;
              height: 140px; /* Further reduce height */
            }

            .growBenefitsCard img {
              width: 40px; /* Further reduce image size */
            }

            .growBenefitsCardText {
              font-size: 1.1rem; /* Smaller text size */
            }
          }
        `}
      </style>
      <h1 className="growBenefitsTitle">
        It's time to <span>Grow</span>
      </h1>
      <div className="growBenefitsGrid">
        <div className="growBenefitsCard">
          <img src={jobImage} alt="Health & Fitness" />
          <span className="growBenefitsCardText">Find Jobs across India</span>
        </div>
        <div className="growBenefitsCard">
          <img src={personalAdvisor} alt="Wellness Programs" />
          <span className="growBenefitsCardText">Personal Marketing Advisor</span>
        </div>
        <div className="growBenefitsCard">
          <img src={onCallsImage} alt="Nutrition & Diet" />
          <span className="growBenefitsCardText">Find On-Call Opportunities</span>
        </div>
      </div>
    </div>
  );
}

export default GrowBenefits;
