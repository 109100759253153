import React from "react";

import eventImage from "./images/eventsconferences.png";
import courseImage from "./images/trainingcourses.png";
import observershipImage from "./images/observerships.png";

function LearnBenefits() {
  return (
    <div className="learn-benefits-container">
      <style>
        {`
          .learn-benefits-container {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .learn-benefits-title {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .learn-benefits-title span {
            color: #ff007f; /* Pink */
          }

          .learn-benefits-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            max-width: 1200px; /* Increased width of the grid container */
            margin: 0 auto;
          }

          .learn-benefits-card {
            background-color: #333;
            border-radius: 30px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            animation: fadeIn 1.5s ease-in-out;
            height: 200px; /* Keep height intact */
            width: 400px; /* Set fixed horizontal length */
            overflow: hidden; /* Prevents content overflow */
          }

          .learn-benefits-card img {
            width: 60px; /* Adjusted icon size for better proportions */
            height: auto;
            background-color: #333;
          }

          .learn-benefits-card-text {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1rem;
            font-family: "antic";
            color: white;
            white-space: nowrap; /* Prevent wrapping */
            overflow: hidden; /* Hide overflowed text */
            text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
            width: 100%;
            text-align: center;
            background-color: #333;
          }

          .learn-benefits-divider {
            margin: 2rem auto;
            width: 80%;
            height: 2px;
            background-color: #ff007f; /* Pink */
          }

          /* Animations */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .learn-benefits-title {
              font-size: 2rem;
            }

            .learn-benefits-grid {
              grid-template-columns: 1fr; /* One column for smaller screens */
            }

            .learn-benefits-card {
              width: 100%; /* Make cards responsive to screen width */
              height: 160px; /* Adjust height for smaller screens */
            }

            .learn-benefits-card img {
              width: 50px; /* Slightly smaller images for mobile */
            }

            .learn-benefits-card-text {
              font-size: 1.3rem; /* Adjust text size for readability */
            }
          }

          @media (max-width: 480px) {
            .learn-benefits-title {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            .learn-benefits-card {
              padding: 1rem;
              height: 140px; /* Further reduce height */
            }

            .learn-benefits-card img {
              width: 40px; /* Further reduce image size */
            }

            .learn-benefits-card-text {
              font-size: 1.1rem; /* Smaller text size */
            }
          }
        `}
      </style>
      <h1 className="learn-benefits-title">
        It's time to <span>Learn</span>
      </h1>
      <div className="learn-benefits-grid">
        <div className="learn-benefits-card">
          <img src={eventImage} alt="Events & Conferences" />
          <span className="learn-benefits-card-text">Events & Conferences</span>
        </div>
        <div className="learn-benefits-card">
          <img src={observershipImage} alt="Observerships" />
          <span className="learn-benefits-card-text">Observerships</span>
        </div>
        <div className="learn-benefits-card">
          <img src={courseImage} alt="Training Courses" />
          <span className="learn-benefits-card-text">Training Courses</span>
        </div>
      </div>
    </div>
  );
}

export default LearnBenefits;
