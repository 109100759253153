import React from "react";

import airportImage from "./images/airports.png";
import golfImage from "./images/golfgames.png";
import giftImage from "./images/gift.png";

function LiveBenefits() {
  return (
    <div className="liveBenefitsContainer">
      <style>
        {`
          .liveBenefitsContainer {
            background-color: black;
            color: white;
            padding: 2rem;
            font-family: 'Poppins', sans-serif;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .liveBenefitsTitle {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            margin-left: 5%;
          }

          .liveBenefitsTitle span {
            color: #ff007f; /* Pink */
          }

          .liveBenefitsGrid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            max-width: 1200px; /* Increase grid container width */
            margin: 0 auto;
          }

          .liveBenefitsCard {
            background-color: #333;
            border-radius: 30px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            animation: fadeIn 1.5s ease-in-out;
            height: 200px; /* Keep height intact */
            width: 400px; /* Set horizontal length */
            overflow: hidden;
          }

          .liveBenefitsCard img {
            width: 60px; /* Adjust icon size */
            height: auto;
            background-color: #333;
          }

          .liveBenefitsCardText {
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 1rem;
            font-family: "antic";
            color: white;
            white-space: nowrap; /* Prevent text wrapping */
            overflow: hidden; /* Hide overflowed text */
            text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
            width: 100%;
            text-align: center;
            background-color: #333;
          }

          .liveBenefitsDivider {
            margin: 2rem auto;
            width: 80%;
            height: 2px;
            background-color: #ff007f; /* Pink */
          }

          /* Animations */
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          /* Mobile Responsiveness */
          @media (max-width: 768px) {
            .liveBenefitsTitle {
              font-size: 2rem;
            }

            .liveBenefitsGrid {
              grid-template-columns: 1fr; /* Stack cards vertically on small screens */
              max-width: 90%; /* Ensure cards occupy 90% of the screen width */
            }

            .liveBenefitsCard {
              width: 100%; /* Make cards responsive to screen width */
              height: 160px; /* Keep height proportionate */
            }

            .liveBenefitsCard img {
              width: 50px; /* Slightly smaller images for mobile */
            }

            .liveBenefitsCardText {
              font-size: 1.3rem; /* Adjust text size for readability */
            }
          }

          @media (max-width: 480px) {
            .liveBenefitsTitle {
              font-size: 1.8rem;
              margin-bottom: 1.5rem;
            }

            .liveBenefitsCard {
              padding: 1rem;
              height: 140px; /* Further reduce height */
            }

            .liveBenefitsCard img {
              width: 40px; /* Further reduce image size */
            }

            .liveBenefitsCardText {
              font-size: 1.1rem; /* Smaller text size */
            }
          }
        `}
      </style>
      <h1 className="liveBenefitsTitle">
        It's time to <span>Live</span>
      </h1>
      <div className="liveBenefitsGrid">
        <div className="liveBenefitsCard">
          <img src={airportImage} alt="Airport Lounge Access" />
          <span className="liveBenefitsCardText">Airport Lounge Access</span>
        </div>
        <div className="liveBenefitsCard">
          <img src={giftImage} alt="Exclusive Partner Offers" />
          <span className="liveBenefitsCardText">Exclusive Partner Offers</span>
        </div>
        <div className="liveBenefitsCard">
          <img src={golfImage} alt="Golf Games & Lessons" />
          <span className="liveBenefitsCardText">Golf Games & Lessons</span>
        </div>
      </div>
    </div>
  );
}

export default LiveBenefits;
