import React from "react";
import "./Careers.css";
import Footer from "../Footers/Footer.js";
import { Card } from "react-bootstrap";
import FooterNext from "../Footers/FooterNext";

export default function Careers() {
  return (
    <div style={{ background: "black", color: "white", minHeight: "100vh" }}>
      <div>
        <center>
          <h4>Careers @ Dr Planete</h4>
          <p>
            View all current openings for Full Time Roles & Interns here
          </p>
          <p>
            To apply for any of the mentioned roles, email your resume at
            info@drplanete.com with the subject - designation you're applying
            for.
          </p>
        </center>
      </div>

      <div>
        <center>
          <h5>Full Time Open Roles</h5>
        </center>
      </div>
      <div>
        <div style={{ marginBottom: "45px" }}>
          <div>
            {/* Business Development Associate Card */}
            <div>
              <Card
                style={{
                  marginTop: "5px",
                  marginLeft: "35px",
                  marginRight: "35px",
                  marginBottom: "5px",
                  background: "black",
                  color: "white",
                }}
              >
                <Card.Title
                  style={{
                    marginTop: "15px",
                    marginLeft: "15px",
                    marginBottom: "4px",
                  }}
                >
                  Designation: Business Development Associate
                </Card.Title>
                <Card.Subtitle
                  style={{
                    marginTop: "1px",
                    marginLeft: "15px",
                    marginBottom: "1px",
                  }}
                >
                  Location: Remote
                </Card.Subtitle>
                <Card.Body>
                  <p>Job Description</p>
                  <p>
                    &bull; As a Business Development Associate for our
                    professional network platform, your primary responsibility
                    will be to drive the growth and expansion of our platform
                    by developing strategic partnerships and acquiring new
                    users.
                  </p>
                  <p>
                    &bull; Identify and prospect potential partners, including
                    industry associations, professional organizations,
                    educational institutions, and corporate entities, to
                    establish mutually beneficial relationships.
                  </p>
                  <p>
                    &bull; Conduct market research to identify target segments
                    and potential customers, analyze competitor activities, and
                    identify key trends and opportunities in the professional
                    networking space.
                  </p>
                  <p>
                    &bull; Build and maintain a strong pipeline of partnership
                    opportunities, engage with potential partners to present
                    the value proposition of our platform, and negotiate
                    partnership terms.
                  </p>
                  <p>
                    &bull; Collaborate with the marketing team to develop and
                    execute business development strategies and campaigns to
                    increase brand awareness and user acquisition.
                  </p>
                  <p>
                    &bull; Meet and exceed monthly and quarterly targets for
                    partnership sign-ups and user growth while ensuring a high
                    level of partner satisfaction.
                  </p>
                  <p>
                    &bull; Develop marketing collateral, including
                    presentations, proposals, and other materials, to support
                    partnership discussions and drive conversions.
                  </p>
                  <p>
                    &bull; Stay up-to-date with industry trends, competitive
                    landscape, and emerging technologies in professional
                    networking and provide insights and recommendations to the
                    management team.
                  </p>
                  <p>
                    &bull; Work closely with cross-functional teams, including
                    product, engineering, and customer success, to ensure
                    seamless onboarding and integration of new partners and
                    users.
                  </p>
                  <p>
                    &bull; Participate in industry events, conferences, and
                    networking opportunities to promote our platform and
                    establish thought leadership in the professional networking
                    space.
                  </p>
                  <p>Qualifications:</p>
                  <ul>
                    <li>
                      &bull; Bachelor's degree in business, marketing, or a
                      related field. MBA or equivalent preferred.
                    </li>
                    <li>
                      &bull; Proven experience in business development, sales,
                      or account management, preferably in the technology or
                      professional networking industry.
                    </li>
                    <li>
                      &bull; Strong communication and negotiation skills, with
                      the ability to build and maintain relationships with key
                      stakeholders.
                    </li>
                    <li>
                      &bull; Excellent presentation and interpersonal skills,
                      with the ability to effectively communicate the value
                      proposition of our platform to potential partners.
                    </li>
                    <li>
                      &bull; Solid understanding of market dynamics, competitive
                      landscape, and emerging trends in professional
                      networking.
                    </li>
                    <li>
                      &bull; Ability to work independently and manage multiple
                      projects simultaneously while meeting deadlines and
                      achieving targets.
                    </li>
                    <li>
                      &bull; Analytical mindset with the ability to analyze
                      data, identify patterns, and make data-driven decisions.
                    </li>
                    <li>
                      &bull; Proficiency in using CRM systems, project
                      management tools, and Microsoft Office Suite.
                    </li>
                    <li>
                      &bull; Willingness to travel as required to meet with
                      partners and attend industry events.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </div>

            {/* iOS Developer Card */}
           
          </div>
          <div>
              <Card
                style={{
                  marginTop: "5px",
                  marginLeft: "35px",
                  marginRight: "35px",
                  marginBottom: "5px",
                  background: "black",
                  color: "white",
                }}
              >
                <Card.Title
                  style={{
                    marginTop: "15px",
                    marginLeft: "15px",
                    marginBottom: "4px",
                  }}
                >
                  Designation: iOS Developer
                </Card.Title>
                <Card.Subtitle
                  style={{
                    marginTop: "1px",
                    marginLeft: "15px",
                    marginBottom: "1px",
                  }}
                >
                  Location: Remote
                </Card.Subtitle>
                <Card.Body>
                  <p>Job Description</p>
                  <ul>
                    <li>
                      &bull; Design and build advanced applications for the iOS
                      platform.
                    </li>
                    <li>
                      &bull; Collaborate with cross-functional teams to define,
                      design, and ship new features.
                    </li>
                    <li>
                      &bull; Unit-test code for robustness, including edge
                      cases, usability, and general reliability.
                    </li>
                    <li>
                      &bull; Work on bug fixing and improving application
                      performance.
                    </li>
                    <li>
                      &bull; Continuously discover, evaluate, and implement new
                      technologies to maximize development efficiency.
                    </li>
                    <li>
                      &bull; Maintain code quality, organization, and
                      automation.
                    </li>
                    <li>
                      &bull; Collaborate with UI/UX designers to implement
                      beautiful and intuitive user interfaces.
                    </li>
                    <li>
                      &bull; Contribute to the entire app lifecycle, from
                      concept, design, testing, to deployment.
                    </li>
                    <li>
                      &bull; Optimize application performance and
                      responsiveness.
                    </li>
                    <li>
                      &bull; Keep up-to-date with the latest iOS platform
                      updates, tools, and best practices.
                    </li>
                  </ul>
                  <p>Qualifications:</p>
                  <ul>
                    <li>
                      &bull; Bachelor's degree in Computer Science, Software
                      Engineering, or a related field.
                    </li>
                    <li>
                      &bull; Proven experience in iOS app development with a
                      strong portfolio of released applications on the App
                      Store.
                    </li>
                    <li>
                      &bull; Proficient in Swift and Objective-C programming
                      languages.
                    </li>
                    <li>
                      &bull; Understanding of Apple's Human Interface
                      Guidelines and design principles.
                    </li>
                    <li>
                      &bull; Experience with iOS frameworks like UIKit, Core
                      Data, Core Animation, and Core Graphics.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </div>

            <FooterNext/>
        </div>
      </div>
    </div>
  );
}
